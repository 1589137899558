<template>
    <div>
        <v-form ref="form" class="pa-4 d-flex flex-wrap align-center " v-model="formValid" lazy-validation @submit.prevent="getFeedBacks">
            <div class="mx-3">
                <div class="pb-1 fsize14 secondaryColor">*From Date</div>
                <v-menu v-model="dateMenu1" :close-on-content-click="false" transition="scale-transition" offset-y
                    max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field @click:append="dateMenu1 = true" clearable class="apiKeyInput"
                            v-model="computedDateFormatted1" outlined persistent-hint
                            :menu-props="{ bottom: true, offsetY: true }" :rules="fromDateRules"
                            placeholder="Select the From Date" append-icon="mdi-calendar" readonly dense v-bind="attrs"
                            v-on="on"></v-text-field>
                    </template>
                    <v-date-picker :max="new Date().toISOString().substr(0, 10)" v-model="fromDate" :show-current="true"
                        no-title @input="dateMenu1 = false"></v-date-picker>
                </v-menu>
            </div>

            <div class="mx-3">
                <div class="pb-1 fsize14 secondaryColor">*To Date</div>
                <v-menu v-model="dateMenu2" :close-on-content-click="false" transition="scale-transition" offset-y
                    max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field @click:append="dateMenu2 = true" clearable class="apiKeyInput"
                            v-model="computedDateFormatted2" outlined persistent-hint
                            :menu-props="{ bottom: true, offsetY: true }" :rules="toDateRules"
                            placeholder="Select the To Date" append-icon="mdi-calendar" readonly dense v-bind="attrs"
                            v-on="on"></v-text-field>
                    </template>
                    <v-date-picker :max="new Date().toISOString().substr(0, 10)" v-model="toDate" :show-current="true"
                        no-title @input="dateMenu2 = false"></v-date-picker>
                </v-menu>
            </div>

            <div class="mx-3">
              <v-btn type="submit" depressed height="40" color="primary" class="text-capitalize fsize14 mb-1" :loading="getLoader">Submit</v-btn>
            </div>

            <div class="ml-auto" v-if="getUserFeedbacks.length != 0 ">
                <a id="downloadcsv">
                    <!-- <v-btn outlined class="text-capitalize" depressed @click="downloadAsCsv()">Download</v-btn> -->
                    <v-btn @click="downloadAsCsv()" depressed min-width="fit-content" color="primary" class="text-capitalize">
                      Download <v-icon class="mx-2 fsize18">mdi-cloud-download </v-icon>
                    </v-btn>
                </a>
            </div>
            
        </v-form>

        <!-- feedback table -->
        <v-simple-table v-if="getUserFeedbacks.length != 0 && !getLoader ">
                <thead>
                    <tr>
                        <th v-for="(head,id) in tableHeads" :key="id">{{ head.name }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(i, id) in getUserFeedbacks" :key="id">
                        <td>
                            {{ getDateString(new Date(i.date), "d-M-y") }}
                        </td>
                        <td>
                            <span class="primary-color font-weight-bold">{{ i.userId }}</span>
                        </td>
                        <td class="py-2 px-4 feedbackComments" @click="callDialog(i.comments)">
                            <div>
                                {{ i.comments }}
                            </div>
                        </td>
                        <td>{{ i.source }}</td>
                        <td>
                            
                            <span v-if="i.ratings">
                                <span v-for="count in Number(i.ratings)" :key="count" class="fsize18" :class="{'rating-red' : Number(i.ratings) < 3, 'rating-yellow' : Number(i.ratings) == 3, 'rating-green' : Number(i.ratings) >= 4}" >&#9734;</span>
                            </span>
                            ({{ i.ratings }})
                        </td>
                        <td>{{ i.version }}</td>
                    </tr>
                </tbody>
        </v-simple-table>

        <div v-else-if="!getLoader" class="d-flex justify-center pa-5 py-8">
            No Records Found
        </div>

        <!-- comments view dialog -->
        <v-dialog class="json-dialog" v-model="commentsDialog" max-width="600" style="overflow-x:hidden">
          <v-card>
            <div class="d-flex dialog-header" style="justify-content: space-between;border-bottom:-16px solid black">
              <div class="font-weight-bold fsize14 mx-2">Comments</div>
            </div>
            <div class="d-flex flex-wrap pa-4">{{ currentComment }}</div>
          </v-card>
        </v-dialog>
</div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'
import commonjs from '../../mixins/commonFunctions'
export default {
    mixins: [commonjs],
    data() {
        return {
            fromDate: new Date().toISOString().substr(0, 10),
            toDate: new Date().toISOString().substr(0, 10),
            dateMenu1: false,
            dateMenu2: false,
            formValid: true,
            // rules
            fromDateRules: [(v) => !!v || "From Date is required"],
            toDateRules: [(v) => !!v || "To Date is required"],
            tableHeads: [
                {
                    name: 'Date'
                },
                {
                    name: 'User ID'
                },
                {
                    name: 'Review',
                },
                {
                    name: 'Product',
                },
                {
                    name: 'Rating',
                },
                {
                    name: 'Version',
            }],
            commentsDialog: false,
            currentComment: ''
        }
    },
    computed: {
        // getters
        ...mapGetters('feedback',['getUserFeedbacks',"getLoader"]),
        computedDateFormatted1: {
            get() {
                return this.VutifyFormatDate(this.fromDate,'/');
            },
            set() { },
        },
        computedDateFormatted2: {
            get() {
                return this.VutifyFormatDate(this.toDate,'/');
            },
            set() { },
        },
    },
    methods: {
        getFeedBacks() {
            if (this.$refs.form.validate()) {
                let params = {
                    fromDate: this.fromDate,
                    toDate: this.toDate
                }
                this.$store.dispatch('feedback/getUserFeedbacks', params)
            }
        },
        callDialog(comment) {
            this.currentComment = comment
            this.commentsDialog = true
        },
        downloadAsCsv() {
            this.downloadWithArray('feedback', 'feedback', this.getUserFeedbacks)
        }
    },
    mounted() {
        this.getFeedBacks();
    }
}
</script>